import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { setItemToLStore } from "../helpers/hash";
import InputMask from "react-input-mask";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Button from "./atoms/Button";
import { ArrowRight } from "lucide-react";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

function ContactForm({ hideLabels, showPlaceholders, stacked, fontsize, footerContained, grayInputBg = true }) {
  const [offer, setOffer] = useLocalStorage("offers", {});

  const [formInputStates, setFormInputStates] = useState({});
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [formSubmitErrors, setFormSubmitErrors] = useState([]);
  const [sessionSource, setSessionSource] = useState('');
  const [landingURL, setLandingURL] = useState('');
  const [smsConsent, setSmsConsent] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const params = new URLSearchParams(window.location.search);
      let source = "direct";
      if (params.has("utm_source")) {
        source = params.get("utm_source");
      } else if (document.referrer) {
        source = new URL(document.referrer).hostname;
      }
      const storedLandingURL = window.localStorage.getItem("landingURL") || window.location.href;
      setLandingURL(storedLandingURL);
      setSessionSource(source);

      if (!localStorage.getItem("initialSource")) {
        localStorage.setItem("initialSource", source);
      }
      localStorage.setItem("landingURL", storedLandingURL);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedSource = window.localStorage.getItem("initialSource");
      if (storedSource) {
        setSessionSource(storedSource);
      }
    }
  }, []);

  function handleChange(e) {
    setFormInputStates({ ...formInputStates, [e.target.name]: e.target.value });
  }

  function handleSubmit(e) {
    setSubmitInProgress(true);
    e.preventDefault();

    if (!validateForm()) {
      window.scroll(0, 0);
      setSubmitInProgress(false);
      return;
    }

    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        subject:
          "Ornamental Solutions -" +
          (formInputStates.Lastname + ", " + formInputStates.Firstname + " (" + new Date().toLocaleString() + ")"),
        "form-name": form.getAttribute("name"),
        ...formInputStates,
        smsConsent,
        landingURL,
        currentPath:
          typeof window !== "undefined" && window.localStorage && window.localStorage.getItem("crNtPaTH")
            ? window.localStorage.getItem("crNtPaTH")
            : "Not Provided",
        session_source: sessionSource,
      }),
    })
      .then(() => {
        setItemToLStore("form", { date: new Date(), ...formInputStates });
        setTimeout(() => navigate(form.getAttribute("action")), 500);
      })
      .catch((error) => {
        alert(error);
        setSubmitInProgress(false);
      });
  }

  function validateForm() {
    let sanitizePhone = formInputStates.phone?.replace(/_/g, "");

    let formValidateSuccess = true;
    let listOfErrors = [];

    if (!formInputStates.Firstname?.trim() || formInputStates.Firstname.length < 2) {
      listOfErrors.push("First name is a required field.");
      formValidateSuccess = false;
    }
    if (!formInputStates.Lastname?.trim() || formInputStates.Lastname.length < 2) {
      listOfErrors.push("Last name is a required field.");
      formValidateSuccess = false;
    }

    if (sanitizePhone?.length < 10) {
      listOfErrors.push("Please enter a complete phone number.");
      formValidateSuccess = false;
    }
    if (!formInputStates.email?.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      listOfErrors.push("Email is a required field.");
      formValidateSuccess = false;
    }
    if (!formInputStates.description?.trim()) {
      listOfErrors.push("Description cannot be left blank.");
      formValidateSuccess = false;
    }

    setFormSubmitErrors(listOfErrors);

    return formValidateSuccess;
  }

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {offer && Object.keys(offer).length > 0 && (
        <div className="shadow-md bg-white border-l-4 border-brand-500 p-4 text-gray-700 rounded-2xl mb-6 flex space-x-4">
          <div>
            <span className="font-bold font-display block mb-1">Claimed Offers</span>
            <span>{offer.offer}</span>
            <Button size="small" color="secondary" type="button" onClick={() => setOffer(null)}>
              Remove
            </Button>
          </div>
        </div>
      )}

      {formSubmitErrors.length > 0 && (
        <div className="shadow rounded-2xl mb-8 bg-gray-50 p-4">
          <span className="flex mb-2 text-red-600 font-semibold">
            There were some problems with your submission.
          </span>
          <ul>
            {formSubmitErrors.map((error) => (
              <li key={error}>- {error}</li>
            ))}
          </ul>
        </div>
      )}

      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="currentPath" />
      <input type="hidden" name="session_source" value={sessionSource} />

      <div className="grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-6">
        <div className={`${stacked ? "sm:col-span-6" : "sm:col-span-3"}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700 mb-1 ${hideLabels ? "hidden" : ""}`}
            htmlFor="Firstname"
          >
            First Name
          </label>
          <input
            className="bg-gray-50 w-full input p-3 rounded-lg border-gray-100 border focus:outline-none focus:border-gray-400"
            type="text"
            name="Firstname"
            onChange={handleChange}
            required
            placeholder={showPlaceholders ? "First Name" : ""}
          />
        </div>

        <div className={`${stacked ? "sm:col-span-6" : "sm:col-span-3"}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700 mb-1 ${hideLabels ? "hidden" : ""}`}
            htmlFor="Lastname"
          >
            Last Name
          </label>
          <input
            className="bg-gray-50 w-full input p-3 rounded-lg border-gray-100 border focus:outline-none focus:border-gray-400"
            type="text"
            name="Lastname"
            onChange={handleChange}
            required
            placeholder={showPlaceholders ? "Last Name" : ""}
          />
        </div>

        <div className={`${stacked ? "sm:col-span-6" : "sm:col-span-3"}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700 mb-1 ${hideLabels ? "hidden" : ""}`}
            htmlFor="email"
          >
            Email
          </label>
          <input
            className="bg-gray-50 w-full input p-3 rounded-lg border-gray-100 border focus:outline-none focus:border-gray-400"
            type="email"
            name="email"
            onChange={handleChange}
            required
            placeholder={showPlaceholders ? "Email" : ""}
          />
        </div>

        <div className={`${stacked ? "sm:col-span-6" : "sm:col-span-3"}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700 mb-1 ${hideLabels ? "hidden" : ""}`}
            htmlFor="phone"
          >
            Phone
          </label>
          <InputMask
            className="bg-gray-50 w-full input p-3 rounded-lg border-gray-100 border focus:outline-none focus:border-gray-400"
            mask="(999) 999-9999"
            type="text"
            name="phone"
            id="phone"
            onChange={handleChange}
            required
            placeholder={showPlaceholders ? "Phone" : ""}
          />
        </div>

        <div className="sm:col-span-6">
          <label
            className={`block text-sm font-medium leading-5 text-gray-700 mb-1 ${hideLabels ? "hidden" : ""}`}
            htmlFor="description"
          >
            Please tell us more about the project
          </label>
          <textarea
            className="bg-gray-50 w-full input p-3 rounded-lg border-gray-100 border focus:outline-none focus:border-gray-400"
            name="description"
            rows={5}
            onChange={handleChange}
            required
            placeholder={showPlaceholders ? "Project details" : ""}
          />
        </div>
      </div>

      <div className="my-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="form-checkbox h-4 w-4 text-brand-500"
            checked={smsConsent}
            onChange={() => setSmsConsent(!smsConsent)}
          />
          <span className="text-gray-400 ml-2 text-sm">
            *By submitting this checkbox, you agree to receive text messages from Ornamental Solutions about your
            project. You may opt-out of SMS by replying STOP to any SMS message.
          </span>
        </label>
      </div>

      <Button className="w-full mt-2 flex items-center justify-center font-semibold text-white bg-brand-500 py-3 px-5 rounded-2xl">
        Request Estimate
        <ArrowRight size={24} className="ml-2" color="#fff" strokeWidth={2} />
        {submitInProgress && (
          <svg
            className="animate-spin ml-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </Button>
    </form>
  );
}

export default ContactForm;
